import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import image from 'src/assets/images/404.png';
import { t } from 'i18next';
import {Accordion, AccordionTab} from "primereact/accordion";

export type PageNotFoundViewProps = {
  error?: any;
}

export function PageNotFoundView(
  props: PageNotFoundViewProps
) {

  const { error } = props;

  return (
    <div className='surface-section px-4 py-8 md:px-6 lg:px-8'>
      <div className='flex flex-column lg:flex-row justify-content-center align-items-center gap-7'>
        <div className='text-left'>
          <div className='mt-6 mb-3 font-bold text-6xl text-900'>{t('core.router.corerouter.pageNotFound.header')}</div>
          <p className='text-700 text-3xl mt-0 mb-6'>{t('core.router.corerouter.pageNotFound.subTitle')}</p>
          {
            error
            &&
            <>
              <small>Error Code</small>
              <p className='text-500 text-xs' style={{maxWidth: '400px', wordWrap:'break-word'}}>
                {btoa(JSON.stringify(error))}
              </p>
            </>
          }
          {
            error
            &&
            <Button
              label={'Copy Error Code'}
              className={'p-button-outlined mr-2'}
              onClick={
                () => {
                  navigator.clipboard.writeText(btoa(JSON.stringify(error)));
                }
              }
            />
          }
          <Button
              type='button'
              label={t('core.router.corerouter.pageNotFound.buttonLabel')}
              onClick={
                () => {
                  if('caches' in window){
                    caches.keys().then((names) => {
                      names.forEach(async (name) => {
                        console.log('deleting caches...');
                        await caches.delete(name)
                      })
                    })
                  }

                  window.location.href = window.location.origin + '?t=' + new Date().getTime();
                }
              }
          >
          </Button>
        </div>
        <div>
            <img src={image} alt='Image' className='w-full md:w-28rem' />
        </div>
      </div>
    </div>
  );

}

export default PageNotFoundView;
