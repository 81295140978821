import { ProgramsRouter } from 'src/domains/programs/_router';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { AppBar, StayTunedMessage } from 'src/core';
import { DiscoverView } from 'src/domains/programs';
import { RewardsRouter } from 'src/domains/rewards';
import { PageNotFoundView, changeLanguage, loadTranslation } from 'src/shared';
import { useEffect, useState } from 'react';
import { ApiError, MeService } from 'src/apis/api-client';
import { ProfileRouter } from 'src/domains/profile';
import { BasketRouter } from 'src/domains/basket';
import { FlowsRouter } from 'src/domains/flows';
import {stringify} from "react-native-uuid/dist/stringify";
import {useMsal} from "@azure/msal-react";

export function CoreRouter() {

  const [ notified, setNotified ] = useState<boolean>(false);
  const [ error, setError ] = useState<boolean>(false);
  const [ errorObj, setErrorObj ] = useState<any>();

  const navigate = useNavigate();
  const msalInstance = useMsal();

  /**
   * Calls the UserService getMe method.
   * This will create the user, if it doesn't exist in the database for now.
   * Also, during this operation, the referrals will get auto assigned.
   */
  useEffect(
    () => {
      (
        async () => {
          try {
            const val = await MeService.getMe();
            if (val) {
              loadTranslation();
              setNotified(true);
              if (val.languageCode) {
                changeLanguage(val.languageCode);
              }
              if (
                val.hasProfile === false
                && !window.location.pathname.startsWith('/flows', 0)
              ) {
                navigate('/profile', { state: true });
              }
            }
          } catch (error: any) {
            /**
             * Should be a fix for the following problem:
             * https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/349
             */
            if (
              error &&
              error.errorCode === "interaction_required"
            )
            {
              await msalInstance.instance.logoutRedirect();
              return;
            }

            /**
             * If the error is an instance of ApiError, then we can check the status code.
             * If the status code is 400, then the user is not logged in.
             * This means, a redirect-utils will occur automatically. This way, we can avoid that the error page is shown.
             * The error should only be shown, if the status code is not 400.
             */
            if (error instanceof ApiError) {
              if (error.status !== 400 && error.status !== 401) {
                setError(true);
              }
            } else {
              setError(true);
            }

            setErrorObj(error);
            console.error(JSON.stringify(error));
          }
        }
      )();
    },
    []
  );

  return (
    notified
      ?
      <AppBar>
        <Routes>
          <Route path='/flows/*' element={<FlowsRouter />}></Route>
          <Route path='/programs/*' element={<ProgramsRouter />}></Route>
          <Route path='/discover' element={<DiscoverView />}></Route>
          <Route path='/rewards/*' element={<RewardsRouter />}></Route>
          <Route path='/profile/*' element={<ProfileRouter />}></Route>
          <Route path='/basket/*' element={<BasketRouter />}></Route>
          <Route path='/' element={<Navigate to='/programs' replace />} ></Route>

          { /* Routes to defaults. */}
          <Route
            path='404'
            element={<PageNotFoundView />}
          />
          <Route
            path='*'
            element={<Navigate to='/404' replace />}
          />
        </Routes>
      </AppBar>
      : error
        ? <PageNotFoundView error={errorObj} />
        : <StayTunedMessage />
  );
}

export default CoreRouter;
